import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Breakpoint,

  useDimensions,
} from '@bluecurrent/web-component-lib';

import Item from './Item';

export default function Partners(props) {
  const size = useDimensions();

  const ref = React.useRef(null);

  const responsive = () => {
    if (size.width >= Breakpoint.sm && size.width < Breakpoint.lg) return 3;
    if (size.width >= Breakpoint.xs && size.width < Breakpoint.sm) return 2;
    if (size.width < Breakpoint.xs) return 1;
    return 5;
  };

  const arrayWithOriginalId = props.data?.array.map((i, index) => ({
    original_id: index,
    ...i,
  }));

  const height = 75;
  const useSlider = arrayWithOriginalId.length > responsive();

  const duplicateArray = useSlider
    ? [...arrayWithOriginalId, ...arrayWithOriginalId]
    : [...arrayWithOriginalId];

  const arrayWithId = duplicateArray.map((i, index) => ({
    id: index,
    ...i,
  }));

  const [width, setWidth] = React.useState('100%');
  const [data, setData] = React.useState(arrayWithId);
  const [active, setActive] = React.useState(useSlider ? 1 : 0);

  const getWidth = () => {
    if (ref.current.clientWidth !== null) setWidth(ref.current.clientWidth);
  };

  React.useEffect(() => {
    getWidth();
  }, [width]);

  React.useEffect(() => {
    window.addEventListener('resize', getWidth);

    return () => window.removeEventListener('resize', getWidth);
  }, []);

  React.useEffect(() => {
    const array = data;
    const arrayLength = array.length + 1;
    const currentActive = (active + 1) === arrayLength ? 0 : (active + 1);

    if (useSlider) {
      setTimeout(() => {
        setActive(currentActive % arrayLength);

        setData(array);
      }, 2600);
    }
  }, [active]);

  return (
    <motion.div
      ref={ref}
      className="Slider"
      style={{
        height: 75,
        width: '100%',
        marginTop: -10,
        marginBottom: size.width < Breakpoint.sm ? 80 : 200,
        position: 'relative',
      }}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
    >
      <div
        className="SliderTrack"
        style={{
          height,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: useSlider ? 'flex-start' : 'center',
          overflow: 'hidden',
          position: 'relative',
          flexWrap: 'nowrap',
        }}
      >
        {
          data.map((item) => (
            <Item
              /* eslint-disable-next-line no-underscore-dangle */
              key={item._id + item.original_id}
              data={item}
              index={item.id}
              originalIndex={item.original_id}
              length={data.length}
              params={props.params}
              width={useSlider ? (width / responsive()) : (width / arrayWithOriginalId.length)}
              fullWidth={width}
              active={active}
            />
          ))
        }
      </div>
    </motion.div>
  );
}
