const getPartnerImage = (
  id,
  layoutIndex,
  objectIndex,
  partnerIndex,
) => `
  *[_id == "${id}"] {
    layout[${layoutIndex}] {
      Components[${objectIndex}] {
        partners {
          array[${partnerIndex}] {
            'src': logo.asset->url
          }
        }
      },
    }
  }
`;

export default getPartnerImage;
