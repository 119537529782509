import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Colors,
} from '@bluecurrent/web-component-lib';

import getPartnerImage from '../../../services/Sanity/query/getImage/getPartnerImage';

import useSanityData from '../../../hooks/useSanityData';

export default function Item(props) {
  const partner = useSanityData(
    getPartnerImage(
      props.params.id,
      props.params.index,
      props.params.objectIndex,
      props.originalIndex,
    ),
  );

  const src = partner[props.params.layoutIndex]
    ?.layout
    ?.Components
    ?.partners
    ?.array
    ?.src;

  return (
    <motion.div
      style={{
        height: '100%',
        width: props.width ?? '100%',
        minWidth: props.width ?? '100%',
        paddingLeft: 30,
        paddingRight: 30,
        flexBasis: props.width ?? '100%',
        zIndex: 0,
      }}
      initial={{
        transform: 'translate(0px)',
      }}
      animate={{
        transform: `translate(${(
          props.index < (props.active - 1)
            ? props.width * (props.length - props.active)
            : props.width * -(props.active)
        )}px)`,
      }}
      transition={{
        duration: (
          ((props.active + 1) === 1 && props.index === props.length - 1)
          || ((props.active - 2) === props.index)
        )
          ? 0
          : 1,
        ease: 'easeInOut',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: Colors.WHITE,
          backgroundImage: src !== undefined && `url('${src}?q=50')`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </motion.div>
  );
}
